import React from 'react'
import { NavLink } from 'react-router-dom';
import BgImage from '../Images/Asset_9@4x.png'
import Button from '../Components/Button';
import Navbar from './../Components/Navbar';

function Home() {
    return (
        <>
            <Navbar />
            <div className=' flex justify-center mt-28'>
                <img className=' w-1/3' src={BgImage} alt="" />
            </div>
            <h2 className='text-center custom-font text-5xl mt-10'>The moon brought out the Wolf</h2>
            <div className=' flex justify-center mt-8'>
                <NavLink to='#'>
                    <Button className="custom-font">Mint Now!</Button>
                </NavLink>
            </div>
        </>
    )
}

export default Home