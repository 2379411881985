import React from 'react'
import { NavLink } from 'react-router-dom'
import Button from '../Components/Button';
import Navbar from '../Components/Navbar';
function Error() {
    return (
        <>

            <Navbar />
            <div className=' flex justify-center align-middle custom-font text-7xl pt-40'>
                <span>404</span>
            </div>
            <h3 className=' flex justify-center custom-font text-5xl'>Ops! Page not Found</h3>
            <div className=' flex justify-center mt-8'>
                <NavLink to='/'>
                    <Button className="custom-font">Back to Home</Button>
                </NavLink>
            </div>
        </>
    )
}

export default Error