import React from 'react'

function MintInfo(props) {
    return (
        <>

            <div className="group flex mint-roadmap items-center border-none outline-none rounded-[28px] hover:drop-shadow hover:bg-black hover:text-white bg-white py-6 ">
                <div className='  inline-flex '>
                    <h3 className=' text-black group-hover:text-white px-6 custom-font  text-2xl'>{props.percent}</h3>
                </div>
                <div className=' inline-flex  border-l-2 border-black group-hover:border-white'>
                    <p className='text-normal ml-4 text-2xl'>{props.minttext}</p>
                </div>
            </div>
        </>
    )
}

export default MintInfo