import React from 'react'
import MintInfo from '../Components/MintInfo'
import Navbar from '../Components/Navbar'


function Roadmap() {
    return (
        <>
            <Navbar />
            <h1 className='custom-font text-center text-5xl text-black mt-16'>Minting Roadmap</h1>
            <p className=' font-normal mb-20 text-xl flex justify-center mt-6 text-center text-white'>Follow our roadmap as we progress throughout minting, prizes will be awarded to minters with a larger raffle upon completion. <br /> This will lead us to monthly raffles for holders after minting has completed & will be updated throughout the minting process</p>

            <MintInfo percent={'0%'} minttext={'Minting Starts'} />
            <br />
            <MintInfo percent={'20%'} minttext={'$1000 BUSD Raffle For NFTs 1-1000'} />
            <br />
            <MintInfo percent={'30%'} minttext={'Purchase land in the Sandbox Metaverse'} />
            <br />
            <MintInfo percent={'40%'} minttext={'$1000 BUSD Raffle For NFTs 1001-2000'} />
            <br />
            <MintInfo percent={'50%'} minttext={'Begin Building TAP NFT Art Gallery in the Metaverse'} />
            <br />
            <MintInfo percent={'70%'} minttext={'Begin Creation of Metaverse P2E Game'} />
            <br />
            <MintInfo percent={'80%'} minttext={'Community Vote For 20% Of Minting Funds Allocation'} />
            <br />
            <MintInfo percent={'90%'} minttext={'Nominations for Art Gallery Features'} />
            <br />
            <MintInfo percent={'100%'} minttext={'MAYC + $1000(x3) BUSD Raffle For All Minters + 20% Minting Funds Staked'} />
            <br />
            <br />
            <h2 className='sub-head  custom-font text-center text-3xl text-white mt-16'>Rare Mint Bounties</h2>
                            <br />
            <MintInfo percent={'$2000'} minttext={'Mint A Rainbow Fur (1/15) & Win $2000 BUSD'} />

            <br />
            <br />
        </>
    )
}

export default Roadmap

