import React from 'react'
import { useState } from "react";
import { NavLink, Link } from 'react-router-dom';

function Navbar() {
    const [navbar, setNavbar] = useState(false);

    const openTwitter = () => {
        window.open("https://twitter.com/TheAlphaPackNFT");
    };

    const openTelegram = () => {
        window.open("https://t.me/TheAlphaPackNFT");
    };


    return (
        <>
            <nav className="w-full bg-transparent py-5">
                <div className="px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
                    <div>
                        <div className="flex items-center justify-between py-3 md:py-5 md:block">
                            <Link>
                                <h2 className="text-2xl hidden font-bold text-white">LOGO</h2>
                            </Link>
                            <div className="md:hidden">
                                <button
                                    className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                                    onClick={() => setNavbar(!navbar)}
                                >
                                    {navbar ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-6 h-6 text-white"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-6 h-6 text-white"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M4 6h16M4 12h16M4 18h16"
                                            />
                                        </svg>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div
                            className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${navbar ? "block" : "hidden"
                                }`} >
                            <ul className="space-y-8 md:flex md:space-x-28 md:space-y-0">
                                <li className="custom-font text-xl text-white hover:text-black">
                                    <NavLink to='/'>Home</NavLink>
                                </li>
                                <li className="custom-font text-xl text-white hover:text-black">
                                    <NavLink to='roadmap'>Roadmap</NavLink>
                                </li>
                                <li className="custom-font text-xl text-white hover:text-black">
                                    <NavLink to='#'>Mint</NavLink>
                                </li>
                                <li className="custom-font text-xl text-white hover:text-black">
                                    <NavLink to='#'>My NFTs</NavLink>
                                </li>
                            </ul>

                            <div className="mt-3 space-y-2 lg:hidden md:inline-block">
                                <Link onClick={openTwitter} className="inline-block w-full px-4 py-2 text-center text-white bg-gray-600 rounded-md shadow hover:bg-gray-800">Twitter</Link>

                                <Link onClick={openTelegram} className="inline-block w-full px-4 py-2 text-center text-gray-800 bg-white rounded-md shadow hover:bg-gray-100">
                                    Telegram</Link>
                            </div>
                        </div>
                    </div>
                    <div className="text-right flex-1 hidden space-x-2 md:inline-block">
                        <Link onClick={openTwitter} className="custom-font px-4 py-2 text-white bg-gray-900 rounded-md shadow hover:bg-gray-800">
                            Twitter
                        </Link>


                        <Link onClick={openTelegram} target="_blank" className="custom-font px-4 py-2 text-gray-800 bg-white rounded-md shadow hover:bg-gray-100">
                            Telegram
                        </Link>
                    </div>
                </div>
            </nav>

        </>
    )
}

export default Navbar
