import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Error from './Pages/Error';
import Roadmap from './Pages/Roadmap';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/roadmap' element={<Roadmap />} />
          <Route path='*' element={<Error />} />
        </Routes>
      </Router>

    </>
  );
}

export default App;
